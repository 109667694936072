import { graphql, useStaticQuery } from "gatsby"

export const getFeaturedProducts = () => {
  const data = useStaticQuery(graphql`
    query {

        CategoryProducts: allWpProduct(
            filter: {productCategories: {nodes: {elemMatch: {id: {eq: "dGVybTozNTYx"}}}}}
            limit: 4
        ) {
        feature: nodes {
            id
            name
            slug
            image {
                sourceUrl
            }
            productTags {
                nodes {
                id
                name
                }
            }
            ... on WpSimpleProduct {
                price
            }
        }   
    }
  }
    
  `)

  return data.CategoryProducts.feature
}