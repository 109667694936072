import { Box, Heading, Image, SimpleGrid, Stack, Text, VStack } from "@chakra-ui/react"

import {BsCheck} from 'react-icons/bs'
import { FaCheckCircle } from 'react-icons/fa'
import { IconCircleCheck } from "@tabler/icons"
import React from "react"

const Feature = () => (
    <Box as="section" py="84px" px={{lg:"112px", base: 8}} background="#141100">
           <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} columnGap={8} rowGap={{ base: 10, md: 16 }}>
                        
            <Stack spacing={{ base: '4', md: '5' }}>
                <Box background="linear-gradient(124.3deg, #956429 15.74%, #D4A959 31.33%, #E7BD67 49.4%, #F4DB7E 64.04%, #956429 90.05%);"  borderRadius="50%" width="40px" height="40px"><BsCheck size="40px" fill="#141100" ></BsCheck></Box>
                
                    <Stack spacing={{ base: '1', md: '2' }} flex="1">
                        <Text fontSize="18px" fontWeight="700" color="yellow.300" > Online Shopping </Text>
                        <Text color="white" fontSize="16px" fontWeight="400" lineHeight="6">Shop online or by phone, fax, or email for specialty medical supplies delivered right to your door.</Text>
                    </Stack>
            </Stack>
            
            <Stack spacing={{ base: '4', md: '5' }}>
               <Box background="linear-gradient(124.3deg, #956429 15.74%, #D4A959 31.33%, #E7BD67 49.4%, #F4DB7E 64.04%, #956429 90.05%);"  borderRadius="50%" width="40px" height="40px"><BsCheck size="40px" fill="#141100" ></BsCheck></Box>
                <Stack spacing={{ base: '1', md: '2' }} flex="1">
                    <Text fontSize="18px" fontWeight="700" color="yellow.300" >
                        Best Brands
                    </Text>
                    <Text color="white" fontSize="16px" fontWeight="400" lineHeight="6">Wide selection of brand name rheumatology and gynecology products in one convenient shop.</Text>
                </Stack>
            </Stack>
            
            <Stack spacing={{ base: '4', md: '5' }}>
                <Box background="linear-gradient(124.3deg, #956429 15.74%, #D4A959 31.33%, #E7BD67 49.4%, #F4DB7E 64.04%, #956429 90.05%);"  borderRadius="50%" width="40px" height="40px"><BsCheck size="40px" fill="#141100" ></BsCheck></Box>
                <Stack spacing={{ base: '1', md: '2' }} flex="1">
                    <Text fontSize="18px" fontWeight="700" color="yellow.300" >
                        Customer Service
                    </Text>
                    <Text color="white" fontSize="16px" fontWeight="400" lineHeight="6">Helpful customer service agents are standing by to take your order and answer all your questions.</Text>
                </Stack>
            </Stack>          
        </SimpleGrid>
    </Box>
)

export default Feature