import * as React from 'react'

import {
  AspectRatio,
  Box,
  Button,
  Image,
  Link,
  Skeleton,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'

import AddToCart from '../AddToCart';
import useAuth from '../hooks/useAuth'

export const SingleProductCard = ({props}: SingleProductCardProps) => {
  const { loggedIn, user } = useAuth();
  return (
    <>
      <Stack spacing={useBreakpointValue({ base: '4', md: '5' })} minH="558px" >
        <Link href={`/product/${props.slug}`} _hover={{textDecoration: "none !important"}}>
          <Box position="relative" height="448px" className="image-container">
            <AspectRatio ratio={4 / 3} height="448px" className="aspect-ratio">
              <Image
                draggable="false"
                fallback={<Skeleton />}
                borderRadius={useBreakpointValue({ base: 'md', md: 'xl' })}
                className="image"
                src={props.image?.sourceUrl}
                objectFit={"contain !important"}
              />
            </AspectRatio>
          </Box>
          <Stack>
              <Stack spacing="1" pt="20px">
                {props.productTags.nodes?.slice(0,1).map((brand: any) =>
                  <Text color="gray.600" fontSize="sm">{brand.name}</Text>)}
                  
                <Text fontWeight="medium" color={useColorModeValue('gray.700', 'gray.400')} _hover={{ textDecoration: "underline", textDecorationColor: "#3A72C5" }}>
                  {props.name}
                </Text>
                  
                <Text marginTop="2 !important"  _hover={{textDecoration: "none !important"}}> {props.price} </Text>
              </Stack>
          </Stack>
        </Link>
        <Stack align="center">

        {!loggedIn ? (
          <Link className="chakra-button css-1oloaee" href='/log-in'  _hover={{textDecoration: "none !important"}} display='block' margin="0px auto !important" ><Button bg="yellow.600" variant="solid" size="lg" mt="16px !important" > Sign In</Button></Link>
            ) : (
          <AddToCart productId={props.databaseId} />
        )}
         
        </Stack>
      </Stack>
    </>
  )
}

type SingleProductCardProps = {
  props: {
    name: String, 
    slug: String,
    price: string,
    regularPrice: string,
    databaseId: number,
    image?: {
      sourceUrl?: string
    },
    productTags: {
      nodes?: []
      }
    }
}