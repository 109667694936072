import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react"

import { IconShoppingCart } from "@tabler/icons";
import React from "react"
import { navigate } from 'gatsby';

const BannerImage = () => (
    <Box as="section" height="480px" background="linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('https://ace.forgaservers.com/wp-content/uploads/2022/09/hero-new.jpg');" backgroundSize="cover" backgroundPosition="center">
        <VStack px={{lg:"120px", base: 8}} height="inherit" alignItems="flex-start" justifyContent="center">
            <Text color="white"> Your online shop for </Text>
            <Heading fontSize="48px" color="white">Specialty Medical <br></br> Supplies</Heading>
            <Button size="lg" bg="yellow.600" onClick={() => navigate('/shop')} leftIcon={<IconShoppingCart height="16px" width="16px" />} > Shop Now </Button>
        </VStack>
    </Box>
)

export default BannerImage