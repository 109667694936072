import { graphql, useStaticQuery } from "gatsby"

export const getOrthoProducts = () => {
  const data = useStaticQuery(graphql`
    query {
        CategoryProducts: allWpProduct(
            filter: {productCategories: {nodes: {elemMatch: {id: {eq: "dGVybToyNTM1"}}}}}
            limit: 4
        ) {
        category: nodes {
            id
            name
            slug
            image {
                sourceUrl
            }
            productTags {
                nodes {
                id
                name
                }
            }
            ... on WpSimpleProduct {
                price
            }
        }   
    }
    }
  `)

  return data.CategoryProducts.category
}