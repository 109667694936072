import * as React from "react"

import { Box, Button, Container, HStack, Heading, Link, SimpleGrid, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from '@chakra-ui/react';
import type { HeadFC, PageProps } from "gatsby"

import BannerImage from "../components/Sections/BannerImage";
import Feature from "../components/Sections/Feature";
import IndexLastSection from "../components/Sections/IndexLastSection";
import Layout from "../components/Base/Layout"
import RepeatingIndexSection from "../components/Sections/RepeatingIndexSection";
import {SEO} from "../components/Base/Seo"
import { SingleProductCard } from "../components/Product/SingleProductCard";
import { getFeaturedProducts } from '../components/hooks/getFeaturedProducts';
import { getOrthoProducts } from '../components/hooks/getOrhoProducts';
import { graphql } from 'gatsby';

const IndexPage: React.FC<IndexProps> = ({ data }) => {

  const orthoProducts = getOrthoProducts()
  const featuredProducts = getFeaturedProducts()
  
  const { 
    wpPage: {
      home: {
        text, title, bgImage: { sourceUrl },
        textRepeatingTwo, titleRepeatingTwo, bgImageRepeatingTwo: {imageRepeatingTwoSource}
      }
    },
    allWpProductTag: {
      nodes: [{
        products: {nodes}
      }]
    },
      Botox: {
      nodes: [{
        products: {BotoxProducts}
      }]
    },
     Xeomin: {
      nodes: [{
        products: {XeominProducts}
      }]
    },
  } = data

  return (
    <Layout> 
      {/* SECTION 1 */}
      <BannerImage></BannerImage>

      {/* SECTION 2 */}
      <Feature></Feature>

      <Container as="section" bg="white" px={{ base: '8', lg: '12' }} py={{ base: '8', lg: '12' }} width="100%" maxW="100%">
        <Box  pb="64px">
          <HStack justifyContent="space-between" alignItems='center' pb="16px">
            <Box>
              <Heading fontSize="4xl" color="yellow.600"> Best Sellers </Heading>
            </Box>
            <Box>
              <Link href="/shop" fontSize="xl" color="yellow.600" fontWeight="700" > View All </Link>
            </Box>
        </HStack>
        
          <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
            {featuredProducts.map((product:any) => (
              <div key={product.id}>
                <SingleProductCard props={product}></SingleProductCard>
              </div>
            ))}
          </SimpleGrid>
         
        </Box>
         </Container>
      
      {/* REPEATING SECTION 1 */}
      <RepeatingIndexSection href="/category/cosmetic-fillers" title={title} bgImage={sourceUrl} text={text}></RepeatingIndexSection>

      <Container as="section" bg="white" px={{ base: '8', lg: '12' }} py={{ base: '8', lg: '12' }} width="100%" maxW="100%">
      <Box pb="64px">
          <HStack justifyContent="space-between" alignItems='center'  pb="16px">
            <Box>
              <Heading fontSize="4xl" color="yellow.600">Orthopedic Injectibles</Heading>
            </Box>
            <Box>
              <Link href="/category/orthopaedic-injectables" fontSize="xl" color="yellow.600" > View All </Link>
            </Box>
          </HStack>
          <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
            {orthoProducts.map((product:any) => (
              <div key={product.id}>
                <SingleProductCard props={product}></SingleProductCard>
              </div>
            ))}
          </SimpleGrid>
        </Box>
      </Container>

       {/* REPEATING SECTION 2 */}
      <RepeatingIndexSection href="/category/body-sculpting" title={titleRepeatingTwo} bgImage={imageRepeatingTwoSource} text={textRepeatingTwo}></RepeatingIndexSection>

      <Container as="section" bg="white" px={{ base: '8', lg: '12' }} py={{ base: '8', lg: '12' }} width="100%" maxW="100%">
      <Box pb="64px">

          {/* TABS */}
          <Stack spacing="16">
            {['lg'].map((size) => (
              <Tabs key={size} size={size} variant='soft-rounded' colorScheme="blue" align="start">
                <HStack justifyContent="space-between" alignItems='center'  pb="16px">
                  <Heading fontSize="4xl" color="yellow.600"> Popular Brands </Heading>
                  <TabList >
                    <Tab color="gray.600" fontWeight="500" _selected={{ color: 'white', bg: 'yellow.600' }} >Juvederm</Tab>
                    <Tab color="gray.600" fontWeight="500" _selected={{ color: 'white', bg: 'yellow.600' }}>Botox</Tab>
                    <Tab color="gray.600" fontWeight="500" _selected={{ color: 'white', bg: 'yellow.600' }}>Xeomin</Tab>
                  </TabList>
                </HStack>
                <TabPanels>
                  {/* FISRT TAB */}
                <TabPanel>
                  <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
                    {nodes.slice(0,4).map((product:any) => (
                      <div key={product.id}>
                        <SingleProductCard props={product}></SingleProductCard>
                      </div>
                    ))}
                  </SimpleGrid>
                  </TabPanel>

                  {/* SECOND TAB */}
                  <TabPanel>
                    <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
                    {BotoxProducts.slice(0,4).map((product:any) => (
                      <div key={product.id}>
                        <SingleProductCard props={product}></SingleProductCard>
                      </div>
                    ))}
                  </SimpleGrid>
                  </TabPanel>

                  {/* THIRD TAB */}
                    <TabPanel>
                      <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
                      {XeominProducts.slice(0,4).map((product:any) => (
                        <div key={product.id}>
                          <SingleProductCard props={product}></SingleProductCard>
                        </div>
                      ))}
                  </SimpleGrid>
                  </TabPanel>
              </TabPanels>
              </Tabs>
          ))}
        </Stack>  
        </Box>
      </Container>

      <IndexLastSection></IndexLastSection>
    </Layout>
  )
}

type IndexProps = PageProps & {
  data: {
    wpPage: {
      home: {
        text: string, title: string, bgImage: { sourceUrl: string },
        titleRepeatingTwo: string, textRepeatingTwo: string, bgImageRepeatingTwo: {imageRepeatingTwoSource: string}
      }
    },
         allWpProductTag: {
         nodes: [{
           products: {
            nodes: Array<{ name?: string; id: string; databaseId:string, slug: string; image: { sourceUrl: string }; productTags: {nodes: {name: string}} }>
          }
        } ]
      }, 

          Botox: {
      nodes: [{
        products: {
          BotoxProducts: Array<{ name?: string; id: string; databaseId:string, slug: string; image: { sourceUrl: string }; productTags: {nodes: {name: string}} }>
        }
      } ]
      }, 
      Xeomin: {
         nodes: [{
           products: {
            XeominProducts: Array<{ name?: string; id: string; databaseId:string, slug: string; image: { sourceUrl: string }; productTags: {nodes: {name: string}} }>
          }
        } ]
      }, 
  }
}

export const query = graphql`
  query HomePage {
  wpPage(slug: {eq: "home"}) {
    home {
      text
      title
      bgImage {
        sourceUrl
      }

      titleRepeatingTwo
      textRepeatingTwo
      bgImageRepeatingTwo {
        imageRepeatingTwoSource: sourceUrl
      }
    }
  }

    allWpProductTag(filter: {name: {eq: "Juvederm"}}, limit: 4) {
    nodes {
      products {
        nodes {
          name
          databaseId
          slug
          id
          image {
            sourceUrl
          }
           productTags {
              nodes {
                name
              }
            }
          ... on WpSimpleProduct {
            id
            name
            price
          }
        }
      }
    }
  }

  Botox: allWpProductTag(filter: {name: {eq: "Botox"}}, limit: 4) {
    nodes {
      products {
        BotoxProducts: nodes {
          name
          slug
          databaseId
          id
          image {
            sourceUrl
          }
           productTags {
              nodes {
                name
              }
            }
          ... on WpSimpleProduct {
            id
            name
            price
          }
        }
      }
    }
  }

   Xeomin: allWpProductTag(filter: {name: {eq: "Xeomin"}}, limit: 4) {
    nodes {
      products {
        XeominProducts: nodes {
          name
          slug
          databaseId
          id
          image {
            sourceUrl
          }
           productTags {
              nodes {
                name
              }
            }
          ... on WpSimpleProduct {
            id
            name
            price
          }
        }
      }
    }
  }

}
`

export default IndexPage

export const Head = () => (
  <SEO title="AceMedical Wholesale" description="A Wholesale Medical company. Your online shop for Specialty Medical Supplies." />
)

